import React from 'react';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import { graphql } from 'gatsby';
import {
  Container,
  HtmlSitemapContainer,
  HtmlSitemapLink,
  Header,
} from '../styles';
import Footer from '../components/footer/footer';
import { addTrailingSlash } from '../utils';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/globalStyles';

const sitemapData = [
  { title: 'Frequently Asked Questions', path: '/frequently-asked-questions/' },
  { title: 'Help', path: '/help/' },
  {
    title: 'How to connect your cylinder',
    path: '/how-to-connect-your-cylinder/',
  },
  { title: 'How to use pro2go kiosks', path: '/how-to-use-pro2go-kiosks/' },
  { title: 'Pro2Go', path: '/' },
  { title: 'Pro2Go returns policy', path: '/returns/' },
  { title: 'Propane safety guidelines', path: '/safety-guidelines/' },
  { title: 'Trouble with the KIOSK', path: '/trouble-with-the-kiosk/' },
  {
    title: 'Trouble with your cylinder try this',
    path: '/trouble-with-your-cylinder-try-this/',
  },
];
const HtmlSitemap = ({ data }) => {
  const allSlug = data.allSitePage.nodes;
  const filteredArray = allSlug
    ?.filter((item) => {
      if (
        item?.path.trim() !== '/dev-404-page/' &&
        item?.path.trim() !== '/html-sitemap/' &&
        !item?.path.trim().includes('404')
      ) {
        return item;
      }
    })
    .sort((a, b) => a?.path?.localeCompare(b?.path));

  const createTitle = (url) => {
    return sitemapData.map((item) => {
      if (url.trim() === item.path.trim()) {
        return item.title;
      }
    });
  };
  return (
    <>
      <GlobalStyles />{' '}
      <Helmet>
        <title>Site Map</title>
      </Helmet>
      <BootstrapNavbar />
      <HtmlSitemapContainer>
        <div>
          {filteredArray.map((item) => {
            return (
              <>
                <HtmlSitemapLink href={addTrailingSlash(item.path)}>
                  {createTitle(item.path)}
                </HtmlSitemapLink>
                <br />
              </>
            );
          })}
        </div>
      </HtmlSitemapContainer>
      <Footer />
    </>
  );
};

export default HtmlSitemap;

export const pageQuery = graphql`
  query SitemapQuery {
    allSitePage {
      nodes {
        path
      }
    }
  }
`;
