import styled from 'styled-components';
import backgroundImg from '../images/bckgd_page.jpg';

export const HtmlSiteMapHeader = styled.a`
  @media (min-width: 991px) {
    padding-left: ${(props) => props.$leftSpace * 30}px;
  }
`;

export const HtmlSitemapTitle = styled.h4`
  font-size: 20px;
  font-weight: normal;
`;

export const HtmlSitemapMainDiv = styled.div`
  margin: 30px;
`;
export const Container = styled.div`
  background: url(${backgroundImg});
  font-size: 14px;
`;
export const MainContainer = styled.div`
  width: 920px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 100px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: #222;
  max-width: 100%;
  height: 100%;
  min-height:400px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 30px;
  }
`;
export const SubParaText = styled.p`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-size: 1.1em;
  font-style: normal;
  margin-bottom: 17px;
  font-weight: bold !important;
  line-height: inherit;
`;
export const Header = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  margin-top: 0;
  text-rendering: optimizeLegibility;
  margin-bottom: 14px;
  display: block;
  font-style: normal;
`;

export const SubHeader = styled.h3`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #263b91;
  font-size: 1.25em;
  line-height: 1em;
  margin-bottom: 0.25em;
  margin-top: 0;
  font-style: normal;
  text-rendering: optimizeLegibility;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

export const ParaText = styled.p`
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  margin-bottom: 17px;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

export const HtmlSitemapContainer = styled.div`
  width: 920px;
  margin: 0 auto;
  padding: 100px 80px;
  @media(max-width: 991px){
    width: 100%;
  }
`;

export const HtmlSitemapLink = styled.a`
  color: #263b91;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Titillium Web', sans-serif;
  :hover {
    color: #52b0e3;
  }
`;
